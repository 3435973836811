import React from 'react';
// import NetlifyForm from 'react-netlify-form';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import Hero from '../components/hero';
import SEO from '../components/seo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';

function Support() {
  const { cards } = useStaticQuery(
    graphql`
      query {
        cards: allContentfulSocialCard(filter: {category: { eq: "support"}}) {
          edges {
            node {
              title
              description
              slug
              image {
                fixed {
                  src
                }
              }
            }
          }
        }
      }
    `,
  );

  const fbShare = url => {
    window.open(
      `http://www.facebook.com/sharer.php?u=${url}`,
      'sharer',
      `top=${(window.outerHeight-600)/2},left=${(window.outerWidth-600)/2},width=600,height=600`,
    );
  };

  const twitterShare = (text, url) => {
    console.log(window.innerWidth);
    window.open(
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        text,
      )}&url=${url}`,
      'tweet',
      `top=${(window.outerHeight-600)/2},left=${(window.outerWidth-600)/2},width=600,height=600`,
    );
  };
  // const cards = allContentfulSocialCard.edges.map(({ node: { title } }) => (
  //   <div>hello {title}</div>
  // ));
  // console.log(cards);
  return (
    <Layout>
      <SEO title="Show Your Support on Social Media" />

      <Hero size="" title="Show Your Support on Social Media"  color="light" centered />

      <div className="section">
        <div className="container">
          {/* <h2 className="title is-2 has-text-center is-uppercase">Spread the Word on Social Media</h2> */}
          <div className="columns is-multiline">
            {cards &&
              cards.edges.map(
                ({
                  node: {
                    title,
                    description,
                    slug,
                    image: {
                      fixed: { src },
                    }
                  },
                }) => (
                  <div className="column is-half is-one-third-widescreen">
                    <div className="card">
                      <div className="card-image">
                        <figure className="image is-16x9">
                          <img src={src} alt={title} />
                        </figure>
                      </div>
                      <div className="card-content">
                        <div className="content">
                          <p className="title is-5">{title}</p>
                          {description}
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="card-footer-item">
                          <div className="field is-grouped">
                            <div className="control">
                              <button
                                className="button is-primary"
                                type="button"
                                onClick={() => {
                                  fbShare(`https://vannahoward.com/social/${slug}`);
                                }}
                              >
                                <div className="icon">
                                  <FontAwesomeIcon size="" icon={faFacebook} />
                                </div>
                                <span>Share</span>
                              </button>
                            </div>
                            <div className="control">
                              <button
                                className="button is-twitter"
                                type="button"
                                onClick={() => {
                                  twitterShare(title, `https://vannahoward.com/social/${slug}`);
                                }}
                              >
                                <div className="icon">
                                  <FontAwesomeIcon size="" icon={faTwitter} />
                                </div>
                                <span>Tweet</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Support;
